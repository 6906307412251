<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="业态" prop="business_format">
          <a-select :disabled="showType=='detail'" v-model="formData.business_format">
            <a-select-option value="普通住宅">普通住宅</a-select-option>
            <a-select-option value="底商">底商</a-select-option>
            <a-select-option value="别墅">别墅</a-select-option>
            <a-select-option value="公寓">公寓</a-select-option>
            <a-select-option value="会所">会所</a-select-option>
            <a-select-option value="办公楼">办公楼</a-select-option>
            <a-select-option value="总部大楼">总部大楼</a-select-option>
            <a-select-option value="商场">商场</a-select-option>
            <a-select-option value="酒店">酒店</a-select-option>
            <a-select-option value="医院">医院</a-select-option>
            <a-select-option value="学校">学校</a-select-option>
            <a-select-option value="产业园">产业园</a-select-option>
            <a-select-option value="工厂">工厂</a-select-option>
            <a-select-option value="机场">机场</a-select-option>
            <a-select-option value="车站">车站</a-select-option>
            <a-select-option value="码头">码头</a-select-option>
            <a-select-option value="景区">景区</a-select-option>
            <a-select-option value="街道">街道</a-select-option>
            <a-select-option value="公墓">公墓</a-select-option>
            <a-select-option value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="用地性质" prop="land_nature">
          <a-select :disabled="showType=='detail'" v-model="formData.land_nature">
            <a-select-option value="居住用地">居住用地</a-select-option>
            <a-select-option value="公共管理与公共服务用地">公共管理与公共服务用地</a-select-option>
            <a-select-option value="商业服务业设施用地">商业服务业设施用地</a-select-option>
            <a-select-option value="工业用地">工业用地</a-select-option>
            <a-select-option value="物流仓储用地">物流仓储用地</a-select-option>
            <a-select-option value="交通设施用地">交通设施用地</a-select-option>
            <a-select-option value="公用设施用地">公用设施用地</a-select-option>
            <a-select-option value="绿地">绿地</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="区域" prop="region">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.region" />
        </a-form-model-item>
        <a-form-model-item label="单元号" prop="unit_number">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.unit_number" />
        </a-form-model-item>
        <a-form-model-item label="产权人" prop="property_owner">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.property_owner" />
        </a-form-model-item>
        <a-form-model-item label="产权人联系方式" prop="property_contact">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.property_contact" />
        </a-form-model-item>
        <a-form-model-item label="使用人" prop="user">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.user" />
        </a-form-model-item>
        <a-form-model-item label="使用人联系方式" prop="user_contact">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.user_contact" />
        </a-form-model-item>
        <a-form-model-item label="收费面积" prop="chargeable_area">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.chargeable_area" suffix="㎡"/>
        </a-form-model-item>
        <a-form-model-item label="收费标准" prop="charging_standards">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.charging_standards" suffix="元/㎡"/>
        </a-form-model-item>
        <a-form-model-item label="折扣" prop="discount">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.discount" />
        </a-form-model-item>
        <a-form-model-item label="可收费金额" prop="chargeable_amount">
          <a-input :disabled="true" v-model.trim="formData.chargeable_amount" suffix="元"/>
        </a-form-model-item>
        <a-form-model-item label="欠费金额" prop="amount_owed">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.amount_owed" suffix="元" @change="calcCost"/>
          <span style="color: red;font-size: 11px">如果不欠费，请填写“0”，这部分内容填写后，系统会自动计算出“可收费金额”</span>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 390px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {addLedgerManagementCost, getLedgerManagementCostByCondition, modifyLedgerManagementCost} from "A/businessmanagement";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '480px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        business_format:'',
        land_nature:'',
        region:'',
        unit_number:'',
        property_owner:'',
        property_contact:'',
        user:'',
        user_contact:'',
        chargeable_area:'',
        charging_standards:'',
        discount:'',
        chargeable_amount:'',
        amount_owed:'',
        remark:'',
        create_time:"",
        creator:"",
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        business_format: [{required: true, message: '请选择业态'}],
        land_nature: [{required: true, message: '请选择用地性质'}],
        region: [{required: true, message: '请输入区域'}],
        unit_number: [{required: true, message: '请输入单元号'}],
        property_owner: [{required: true, message: '请输入产权人'}],
        property_contact: [{required: true, message: '请输入联系方式'}],
        user: [{required: true, message: '请输入使用人'}],
        user_contact: [{required: true, message: '请输入联系方式'}],
        chargeable_area: [{required: true, message: '请输入收费面积'}],
        charging_standards: [{required: true, message: '请输入收费标准'}],
        discount: [{required: true, message: '请输入折扣'}],
        // chargeable_amount: [{required: true, message: '请输入可收费金额'}],
        amount_owed: [{required: true, message: '请输入欠费金额'}],
      },
      monitorpointList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.ledger_id) {
        if(this.showType == 'edit' || this.showType == 'detail' || this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              ledger_id: this.detailData.ledger_id
            }
            getLedgerManagementCostByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit' || this.showType === 'paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.create_time=moment(new Date()).format("YYYYMMDDHHmmss")
            this.formData.creator=this.userInfo.username
            this.calcCost()
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add' || this.showType === 'paste') {
              this.showLoading();
              addLedgerManagementCost(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyLedgerManagementCost(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    calcCost(){
      this.formData.chargeable_amount=(this.formData.chargeable_area*this.formData.charging_standards*this.formData.discount).toFixed(2)
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>